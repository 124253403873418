import { JsonProperty, Serializable } from "typescript-json-serializer"
import DiamondFile from "@/types/DiamondFile"

@Serializable()
export default class ProductCategoryDimension {
  @JsonProperty("product_group_dimension_id") groupId!: number

  @JsonProperty() value!: string
  
  @JsonProperty("product_group_dim_sort") sort!: number

}
