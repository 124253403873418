import DiamondBaseAPI from "@/api/Api"
import ResponseBase from "@/types/ResponseBase"
import ProductGroup from "@/types/Product/ProductGroup"
import ProductCategory from "@/types/Product/ProductCategory"
import ProductCategoryFilter from "@/types/Product/ProductCategoryFilter"
import ProductCategoryDetail from "@/types/Product/ProductCategoryDetail"
import Product from "@/types/Product/Product"
import GroupColor from "@/types/Product/GroupColor"
import Color from "@/types/Product/Color"
import SiteRefProductGroup from "@/types/SupportAndDownload/Support/SiteRefProductGroup"
import ProductGroupDimension from "@/types/Product/ProductGroupDimension"

export default class ProductApi extends DiamondBaseAPI {
  static listProductGroups(): Promise<ResponseBase<ProductGroup>> {
    return this.api.get("product-group").then(({ data }) => data)
  }

  static listProductGroupsFilter(): Promise<ResponseBase<SiteRefProductGroup>> {
    return this.api.get("product-group-for-filter").then(({ data }) => data)
  }

  static listCategories(groupId: number, query = {}): Promise<ResponseBase<ProductCategory>> {
    const params = {
      // eslint-disable-next-line @typescript-eslint/camelcase
      product_group: groupId,
      ...query
    }
    return this.api
      .get("product-category", {
        params
      })
      .then(({ data }) => data)
  }

  static listCategoryFilter(): Promise<ResponseBase<ProductCategoryFilter[]>> {
    return this.api.get("product-category/filter").then(({ data }) => data)
  }

  static getProductCategoryDetail(categoryId: number): Promise<ResponseBase<ProductCategoryDetail>> {
    const params = {
      // eslint-disable-next-line @typescript-eslint/camelcase
      product_category: categoryId
    }
    return this.api.get("product-category/detail?videoSort=YES&docSort=YES", { params }).then(({ data }) => data)
  }

  static listGroupColors(categoryId: number): Promise<ResponseBase<GroupColor[]>> {
    const params = {
      // eslint-disable-next-line @typescript-eslint/camelcase
      product_category: categoryId
    }
    return this.api.get("group-colors", { params }).then(({ data }) => data)
  }

  static listColors(categoryId: number, groupColorId: number): Promise<ResponseBase<Color>> {
    const params = {
      // eslint-disable-next-line @typescript-eslint/camelcase
      product_category: categoryId,
      // eslint-disable-next-line @typescript-eslint/camelcase
      group_color: groupColorId
    }
    return this.api.get("colors", { params }).then(({ data }) => data)
  }

  static getProduct(categoryId: number): Promise<ResponseBase<Product>> {
    const params = {
      // eslint-disable-next-line @typescript-eslint/camelcase
      product_category: categoryId
    }
    return this.api.get("products", { params }).then(({ data }) => data)
  }

  static getProductGroupDimension(groupId: number): Promise<ResponseBase<ProductGroupDimension[]>> {
    return this.api.get(`product-group-dimensions/product-group/${groupId}`).then(({ data }) => data)
  }

  static getProductCategoryDimension(cateId: number): Promise<ResponseBase<ProductGroupDimension[]>> {
    return this.api.get(`product-category-dimensions/${cateId}`).then(({ data }) => data)
  }
}
